export const TAGLINES = {
  2021: '«Герои в белых халатах»',
  2022: '«Искусство спасать жизни»',
  2023: '«Сияющие звёзды медицины»',
  2024: '«По долгу призвания, по зову сердца»',
}

export const DOCS = {
  2020: '/documents/year-2020/Положение_о_Всероссийской_премии_ПроДокторов_2020.pdf',
  2021: '/documents/year-2021/Положение_о_Всероссийской_премии_ПроДокторов_2021.pdf',
  2022: '/documents/year-2022/Положение_о_Всероссийской_премии_ПроДокторов_2022.pdf',
  2023: '/documents/year-2023/Положение_о_Всероссийской_премии_ПроДокторов_2023.pdf',
  2024: '/documents/year-2024/Положение_о_Всероссийской_премии_ПроДокторов_2024.pdf',
  2025: '/documents/year-2025/Положение_о_Всероссийской_премии_ПроДокторов_2025.pdf',
}
